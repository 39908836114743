import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { DesktopOnly, H6Style, MobileOnly } from '../../util/typography';
import Button from '../Button';
import { downloadArrowWhite, downloadBarWhite } from '../../util/icons';

const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + 1px), ${rightColor} 100%)`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 280px;
    ${respondTo.md`
        gap: 30px;
        max-width: 471px;
        flex-direction: row;
    `}
`;

const ResourceLibraryContainer = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: start;
    padding: 0 20px 60px 20px;
    max-width: 1032px;
    margin: 0 auto;
    ${respondTo.md`
        gap: 30px;
        padding: 0 30px 60px 30px;
    `}
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${colors.charcoal};
`;

const CardButton = styled(Button)`
    &.tertiary-dark {
        padding: 0;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        ${H6Style};
        position: relative;
        padding-right: 28px;
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            background-image:url(${downloadBarWhite}), url(${downloadArrowWhite}), ${splitGradient(colors.greenDark, colors.greenRollOver)};
            background-position: bottom center, center 35%, left;
            background-repeat: no-repeat;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background-size: 10px 10px, 10px 10px, 200% 100%;
        }
        &:hover {
            &:after {
                animation-duration: .4s;
                animation-name: download-animation;
                animation-fill-mode: both;
            }
        }
        @keyframes download-animation {
            0% {
                background-position: bottom center, center 50%, left;
            }
    
            50% {
                background-position: bottom center, center calc(100% + 15px), center;
            }
    
            50.001% {
                background-position: bottom center, center calc(0% - 15px), center;
            }
    
            100% {
                background-position: bottom center, center 35%, right;
            }
        }
    }
`;

const CardImage = styled.img`
    width: 52.571px;
    height: 64px;
    ${respondTo.md`
        width: 65.714px;
        height: 80px;
    `}
`;

const CardImageContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    ${respondTo.md`
        align-items: start;
    `}
`;

const Card = ({img, title, children, props, to, imgAlt, href}) => {
    return (
        <CardContainer {...props}>
            <CardImageContainer>
                <CardImage src={img} alt={imgAlt}/>
                <MobileOnly>
                    <CardButton type='tertiary-dark' noIcon  to={to} target="_blank" href={href}>{title}</CardButton>
                </MobileOnly>
            </CardImageContainer>
            <CardContent>
                <DesktopOnly>
                    <CardButton type='tertiary-dark' noIcon to={to} target="_blank"  href={href}>{title}</CardButton>
                </DesktopOnly>
                {children}
            </CardContent>
        </CardContainer>
)};

const ResourceLibrary = ({children}) => {
    return (
        <ResourceLibraryContainer>
            {children && children.length && children.map((card, index) => (
                <Card img={card.props.src} title={card.props.title} key={index} to={card.props.to} href={card.props.href} imgAlt={card.props.alt}>{card.props.children}</Card>
            ))}
        </ResourceLibraryContainer>
)};
export default ResourceLibrary;